import React from 'react'
import Logo from '../../assets/images/LogoWhite.svg'
import LinkedInIcon from '../../assets/images/LinkedIn.svg'
import { data } from './data'
import ISO9001 from '../../assets/images/mark-of-trust-certified-ISO-9001-quality-management-systems-white-logo-En-GB-1019.png';
import './styles.scss'

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const footerDataMapping = data.map((item) => {
    return (
      <div className='footer-navigation-card'>
        <span className='paragraphWhite footer-item-title'>{item.title}</span>
        {
          item.data.map((element) => {
            return (
              <span className='paragraphWhite footer-item-link'>{element.name}</span>
            )
          })
        }
      </div>
    )
  })

  return (
    <div className='footer-name'>
      <div className='top-part'>
        <img src={Logo} alt='Nostra Pharma logo' />
        <span className='paragraphWhite'> Where Compliance Meets Excellence.</span>
      </div>
      <div className='bottom-part'>
        <div className='navigation-section'>
          { footerDataMapping }
          <div className="iso-img">
            <img src={ISO9001} alt="iso-9001"/>
          </div>
        </div>
        <div className='contact-section'>
          <span className='paragraphWhite footer-item-link'>15 Bell Street Reigate
            Surrey
            RH2 7AD</span>
          <span className='paragraphWhite footer-item-link'>E-mail: contact@nostrapharma.com</span>
          {/* <span className='paragraphWhite'>Call: +38762439597</span> */}
        </div>
        <hr className='horizontalLine' />
        <div className='contact-section copyright'>
          <span className='paragraphWhite footer-item-link'>{`Copyright © ${currentYear} NostraPharma | All Rights Reserved | Privacy Policy`}</span>
          <div className='footer-social-media-icons'>
            <img src={LinkedInIcon} alt='social-media-icon' />
          </div>
        </div>
      </div>
    </div>
  )
}
export default Footer
